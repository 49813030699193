import { createSlice } from '@reduxjs/toolkit';
// @types
import { RootProductTemplateType } from '_types/configTemplate';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

type InitialState = {
  isLoading: boolean;
  error: Error | string | null;
  rootComponentConfig: RootProductTemplateType;
  selectedSystemCode: string;
};

const initialState: InitialState = {
  isLoading: false,
  error: null,
  selectedSystemCode: '',

  // ROOT PRODUCT TEMPLATE
  rootComponentConfig: {
    id: 'root',
    width: 400,
    height: 400,
    drawingWidth: 400,
    drawingHeight: 400,
    frameSize: 0,
    splitDirection: null,
    newId: 1,
    sections: [
      {
        nodeType: 'section',
        parentId: 'root',
        id: 1,
        width: 400,
        height: 400,
        drawingWidth: 400,
        drawingHeight: 400,
        frameSize: 0,
        type: 'none', // none left right  tilt,left tilt,right
        splitDirection: null, //vertical horizontal
        sections: [],
      },
    ],
  },
};

const slice = createSlice({
  name: 'componentConfig',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // UPDATE ROOT PRODUCT TEMPLATE
    updateRootComponentTemplate(state, action) {
      state.rootComponentConfig = action.payload;
    },
    updateRootComponentTemplateClean(state) {
      state.rootComponentConfig = initialState.rootComponentConfig;
    },
    updateSelectedComponentConfigurationSystemCode(state, action) {
      state.selectedSystemCode = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

export const { actions } = slice;

// ----------------------------------------------------------------------

export function updateSelectedComponentConfigurationSystemCode(code: string) {
  return () => {
    dispatch(slice.actions.updateSelectedComponentConfigurationSystemCode(code));
  };
}
// ----------------------------------------------------------------------

export function updateRootComponentTemplate(root: RootProductTemplateType) {
  return () => {
    try {
      dispatch(slice.actions.updateRootComponentTemplate(root));
    } catch (error) {}
  };
}

// ----------------------------------------------------------------------

export function updateRootComponentTemplateClean() {
  return () => {
    try {
      dispatch(slice.actions.updateRootComponentTemplateClean());
    } catch (error) {}
  };
}
