import * as endpoints from 'services/labour/endpoints';
import * as api from 'utils/axios';

export const getLabourList = () =>
  api.sendGet(endpoints.GET_LABOURS, {
    headers: { 'Content-Type': 'application/json', accept: 'application/json' },
    data: {},
  });

export const addLabour = (params: any) => api.sendPost(endpoints.ADD_LABOUR, params);

export const updateLabour = (id: string, params: any) =>
  api.sendPatch(endpoints.UPDATE_LABOUR.replace('{id}', id), params, {
    headers: { 'Content-Type': 'application/json' },
  });

export const getLabourById = (id: string) =>
  api.sendGet(endpoints.GET_LABOUR.replace('{id}', id), {
    headers: { 'Content-Type': 'application/json', accept: 'application/json' },
    data: {},
  });

export const updateLabourStatus = (id: string, isActive: boolean) =>
  api.sendPatch(
    `${endpoints.UPDATE_LABOUR_STATUS.replace('{id}', id)}/${isActive.toString()}`,
    null,
    {
      headers: { 'Content-Type': 'application/json' },
    }
  );
