// components
import Iconify from 'components/Iconify';
import SvgIconStyle from 'components/SvgIconStyle';
import { PATH_PAGE } from 'routes/paths';
import { PERMISSION } from 'utils/constants';
// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  project: <Iconify icon="fluent-emoji-high-contrast:window" />,
  artificialIntelligence: getIcon('ic_artificial_intelligence'),
  action: getIcon('ic_action'),
  scorecard: getIcon('ic_scorecard'),
  chat: getIcon('ic_chat'),
  chatQuestionnaire: getIcon('ic_chat_questionnaire'),
  settings: getIcon('ic_settings'),
  account: getIcon('ic_account'),
};

const navConfig = [
  // MAIN
  // ----------------------------------------------------------------------
  {
    subheader: 'Design Smart',
    items: [

      {
        title: 'Project',
        path: PATH_PAGE.project.list,
        icon: ICONS.project,
        roles: [PERMISSION.ROLE_USER],
      },
    ],
  },
  // Tools
  {
    subheader: 'tools',
    items: [
      {
        title: 'Tools',
        path: '/tools',
        icon: ICONS.settings,
        roles: [PERMISSION.ROLE_USER],

        children: [
          { title: 'transfer orders', path: PATH_PAGE.tools.root },

        ],
      },
    ],
  },
  // Administration
  {
    subheader: 'administration',
    items: [
      // USER
      {
        title: 'Administration',
        path: '/admin',
        icon: ICONS.chatQuestionnaire,
        roles: [PERMISSION.ROLE_ADMIN],
        children: [
          { title: 'Product Template', path: PATH_PAGE.productTemplate.root },
          { title: 'Component Template', path: PATH_PAGE.componentTemplate.root },
          { title: 'System Configuration', path: PATH_PAGE.systemConfiguration },
          { title: 'Material List', path: PATH_PAGE.materialList },
          { title: 'Labour', path: PATH_PAGE.labour },
          { title: 'Configuration Template', path: PATH_PAGE.configTemplate.root },
          { title: 'Component Configuration', path: PATH_PAGE.componentConfiguration.root },
        ],
      },
    ],
  },

];

export { navConfig };

