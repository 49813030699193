import * as endpoints from 'services/product-template/endpoints';
import * as api from 'utils/axios';

export const createProductTemplate = (params: any) =>
  api.sendPost(endpoints.PRODUCT_TEMPLATE, params);

export const getProductTemplateDetail = (id: string) =>
  api.sendGet(endpoints.PRODUCT_TEMPLATE + `/${id}`, {
    headers: { 'Content-Type': 'application/json', accept: 'application/json' },
    data: {},
  });

export const getProductTemplateBySystem = (code: string) =>
  api.sendGet(endpoints.GET_PRODUCT_TEMPLATE_BY_SYSTEM + `${code}`, {
    headers: { 'Content-Type': 'application/json', accept: 'application/json' },
    data: {},
  });

export const deleteProductTemplate = (productTemplateId: string) =>
  api.sendDelete(`${endpoints.DELETE_PRODUCT_TEMPLATE}/${productTemplateId}`, {
    headers: { 'Content-Type': 'application/json' },
    data: {
      productTemplateId,
    },
  });

export const updateProductTemplate = (id: string, params: any) =>
  api.sendPut(`${endpoints.UPDATE_PRODUCT_TEMPLATE}/${id}`, params, {
    headers: { 'Content-Type': 'application/json' },
  });

export const updateDisplayIndex = (data: any) => api.sendPost(endpoints.UPDATE_DISPLAY_INDEX, data);
