// hooks
import useAuth from 'hooks/useAuth';
// constants
import { PERMISSION } from 'utils/constants';

// ----------------------------------------------------------------------

export function useCheckPermission(role: string) {
  const { user } = useAuth();

  return user && user.roles && user.roles.includes(role);
}
// ----------------------------------------------------------------------

export function useIsAdmin() {
  const { user } = useAuth();
  return user && user.roles && user.roles.includes(PERMISSION.ROLE_ADMIN);
}

// ----------------------------------------------------------------------

export const useHasPermission = (requiredRoles: string[]) => {
  const { user } = useAuth();

  return user && user.roles && user.roles.some((role: string) => requiredRoles.includes(role));
};

// ----------------------------------------------------------------------
