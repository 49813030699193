import { createSlice } from '@reduxjs/toolkit';
// @types
import {
  GetProjectsParamsType,
  ProjectParamsType,
  ProjectStateType,
  ProjectType,
} from '_types/project';
// redux
import { dispatch } from '../store';
// services
import * as services from 'services/project';
import { sortBy } from 'lodash';

// ----------------------------------------------------------------------

const initialState: ProjectStateType = {
  isLoading: false,
  error: null,
  projects: [],
  totalRecords: 0,
  project: null,
  headFlashingByProjectId: [],
  lineByProjectId: [],
  supportBarByProjectId: [],
};

const slice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.project = null;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // UPDATE PROJECTS
    setProjects(state, action) {
      state.isLoading = false;
      const { totalRecords, data } = action.payload;
      state.totalRecords = totalRecords;
      state.projects = data;
    },

    setProject(state, action) {
      state.isLoading = false;
      state.project = action.payload;
    },

    update(state, action) {
      state.isLoading = false;
      state.project = action.payload;
    },

    // CLEAR ERROR
    clearError(state) {
      state.error = null;
    },

    updateFlashingByProjectId(state, action) {
      state.isLoading = false;
      const data = sortBy(action.payload, ['code']);
      state.headFlashingByProjectId = data;
    },
    updateLineByProjectId(state, action) {
      state.isLoading = false;
      const data = sortBy(action.payload, ['code']);
      state.lineByProjectId = data;
    },
    updateSupportBarByProjectId(state, action) {
      state.isLoading = false;
      const data = sortBy(action.payload, ['code']);
      state.supportBarByProjectId = data;
    },
  },
});

// Reducer
export default slice.reducer;
export const { actions } = slice;

// ----------------------------------------------------------------------

export function addProject(project: Partial<ProjectParamsType>) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: {
        data: ProjectType;
      } & any = await services.addProject(project);
      return response?.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error?.message || 'An error has occurred'));
    }
  };
}

// ----------------------------------------------------------------------

export function getProjects(params: GetProjectsParamsType) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      services
        .getProjects(params)
        .then((response: any) => {
          dispatch(slice.actions.setProjects(response));
        })
        .catch((error: any) => {
          dispatch(slice.actions.hasError(error?.message || 'An error has occurred'));
        });
    } catch (error) {
      const errorMessage =
        error?.message ||
        error?.response?.data?.message ||
        error?.response?.data?.error ||
        'An error has occurred';
      dispatch(slice.actions.hasError(errorMessage || 'An error has occurred'));
    }
  };
}

// ----------------------------------------------------------------------

export function getProject(projectData: any) {
  return dispatch(slice.actions.setProject(projectData));
}

// ----------------------------------------------------------------------

export function updateProject(id: string, params: ProjectParamsType) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: {
        data: ProjectType;
      } & any = await services.updateProject(id, params);
      dispatch(slice.actions.setProject(response?.data));
      return response?.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error?.message || 'An error has occurred'));
    }
  };
}
// ----------------------------------------------------------------------

export function updateFlashingByProjectId(data: any) {
  return () => {
    try {
      dispatch(slice.actions.updateFlashingByProjectId(data));
    } catch (error) {}
  };
}
// ----------------------------------------------------------------------

export function updateLineByProjectId(data: any) {
  return () => {
    try {
      dispatch(slice.actions.updateLineByProjectId(data));
    } catch (error) {}
  };
}

export function updateSupportBarByProjectId(data: any) {
  return () => {
    try {
      dispatch(slice.actions.updateSupportBarByProjectId(data));
    } catch (error) {}
  };
}
