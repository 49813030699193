import * as endpoints from 'services/product/endpoints';
import * as api from 'utils/axios';

export const addProduct = (params: any) => api.sendPost(endpoints.ADD_PRODUCT, params);

export const getProductListByProjectId = (projectId: string, partition: string) =>
  api.sendGet(endpoints.GET_PRODUCTS.replace('{projectId}', projectId) + `/${partition}`, {
    headers: { 'Content-Type': 'application/json', accept: 'application/json' },
    data: {},
  });

export const getProductTemplateListBySystemAndType = (systemCode: string, typeCode: string) =>
  api.sendGet(
    endpoints.GET_PRODUCT_TEMPLATE_BY_TYPE_SYSTEM.replace('{systemCode}', systemCode).replace(
      '{typeCode}',
      typeCode
    ),
    {
      headers: { 'Content-Type': 'application/json', accept: 'application/json' },
      data: {},
    }
  );

export const getProductTemplateById = (productTemplateId: string) =>
  api.sendGet(
    endpoints.GET_PRODUCT_TEMPLATE_BY_ID.replace('{productTemplateId}', productTemplateId),
    {
      headers: { 'Content-Type': 'application/json', accept: 'application/json' },
      data: {},
    }
  );

export const updateProduct = (params: any) =>
  api.sendPut(endpoints.UPDATE_PRODUCT.replace('{productId}', params?.id), params, {
    headers: { 'Content-Type': 'application/json' },
  });

export const insertProduct = (params: { productId: string; request: any }) =>
  api.sendPost(
    endpoints.INSERT_PRODUCT.replace('{selectedProductId}', params.productId),
    params.request
  );

export const updateToggleMetalLiner = (productId: string) =>
  api.sendPatch(
    endpoints.UPDATE_TOGGLE_METAL_LINER.replace('{productId}', productId),
    { productId: productId },
    {
      headers: { 'Content-Type': 'application/json' },
    }
  );

export const updateTimberLiner = (params: { productId: string; timberLinerId: string }) =>
  api.sendPatch(
    endpoints.UPDATE_TIMBER_LINER.replace('{productId}', params.productId),
    params.timberLinerId,
    {
      headers: { 'Content-Type': 'application/json' },
    }
  );

export const deleteProduct = (productId: string) =>
  api.sendDelete(endpoints.DELETE_PRODUCT.replace('{productId}', productId), {
    headers: { 'Content-Type': 'application/json', accept: 'application/json' },
    data: {},
  });

export const getProductImageBase64 = (productId: string) =>
  api.sendGet(endpoints.GET_PRODUCT_IMAGE_BASE64.replace('{productId}', productId), {
    headers: { 'Content-Type': 'application/json', accept: 'application/json' },
    data: {},
  });

export const getPrefab = (systemCode: string) =>
  api.sendGet(`${endpoints.GET_PREFAB}?systemCode=${systemCode}&dataType=PREFAB`, {
    headers: { 'Content-Type': 'application/json', accept: 'application/json' },
    data: {},
  });

export const getPrefabComponent = (systemCode: string) =>
  api.sendGet(`${endpoints.GET_PREFAB}?systemCode=${systemCode}&dataType=COMPONENT`, {
    headers: { 'Content-Type': 'application/json', accept: 'application/json' },
    data: {},
  });

export const generateProductNameById = (productId: string, partition: string) =>
  // api.sendGet(endpoints.GENERATE_PRODUCT_NAME, {
  //   headers: { 'Content-Type': 'application/json', accept: 'application/json' },
  //   data: { prefix: 'P', projectId: productId, partition: partition },
  // });

  api.sendPost(endpoints.GENERATE_PRODUCT_NAME, {
    prefix: 'P',
    projectId: productId,
    partition: partition,
  });

export const duplicateProduct = (productId: string) =>
  api.sendPost(endpoints.DUPLICATE_PRODUCT.replace('{productId}', productId), {
    productId: productId,
  });

export const updateDeploymentConfig = (params: any) =>
  api.sendPost(endpoints.UPDATE_DEPLOYMENT_CONFIG, params);

export const generateAngleList = (params: any) =>
  api.sendPost(endpoints.GENERATE_ANGLE_LIST, params);
