export const GET_PROJECT = '/api/project/{id}';
export const UPDATE_PROJECT = '/api/project/{id}';
export const ADD_PROJECT = '/api/project';
export const SEARCH_PROJECTS = '/api/project/search';
export const UPDATE_PROJECT_STATUS = '/api/project/toggleStatus/{id}';
export const CLONE_PROJECT = '/api/project/{projectId}/clone';
export const DELETE_PROJECT = '/api/project';
export const DOWNLOAD_ALU_CUTTING_SHEET = '/api/quotation/alu-cutting/{projectId}';
export const START_PRODUCTION = '/api/project/{projectId}/start-production';
export const GET_R_VALUE = '/api/metadata/value-r';
export const DUPLICATE_PROJECT = '/api/project/{projectId}/duplicate';
export const GET_EXTRA_FLASHING_BY_PROJECT_ID = '/api/extra-product/flashing/{projectId}';
export const UPDATE_EXTRA_FLASHING_BY_PROJECT_ID = '/api/extra-product/flashing/{projectId}';
