import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
import * as services from 'services/component-template';
import { VARIABLE_TYPE } from 'utils/constants';
import { FormulaObject } from '_types/product';
import _ from 'lodash';

// ----------------------------------------------------------------------

type InitialState = {
  isLoading: boolean;
  error: Error | string | null;
  componentTemplateList: any;
  isUpdateFormulaLoading: boolean;
  heightVariable: { code: string; type: string; formula?: any[] }[];
  widthVariable: { code: string; type: string; formula?: any[] }[];
  variable: string[];
  glassVariable: string[];
  selectedSystemCode: string;
};

const initialState: InitialState = {
  isLoading: false,
  error: null,
  componentTemplateList: [],
  isUpdateFormulaLoading: false,
  heightVariable: [{ code: 'HC', type: VARIABLE_TYPE.DIMENSION }],
  widthVariable: [{ code: 'WC', type: VARIABLE_TYPE.DIMENSION }],
  variable: [],
  glassVariable: [],
  selectedSystemCode: '',
};

const slice = createSlice({
  name: 'componentTemplate',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    updateComponentTemplateByType(state, action) {
      state.isLoading = false;
      state.componentTemplateList = action.payload;
    },

    updateComponentConfigurationVariable(state, action) {
      state.isUpdateFormulaLoading = false;
      const dimensions = action.payload?.dimensionData as [];
      const heightVariable: { code: string; type: string; formula?: any[] }[] = [
        { code: 'HC', type: VARIABLE_TYPE.DIMENSION },
      ];
      const widthVariable: { code: string; type: string; formula?: any[] }[] = [
        { code: 'WC', type: VARIABLE_TYPE.DIMENSION },
      ];
      const labelGlass: string[] = [];
      dimensions?.forEach((dimensionItem: any, index) => {
        // set Glass ID item
        if (dimensions?.length < 2) {
          if (dimensionItem?.labelGlass?.startsWith('G')) {
            labelGlass.push(dimensionItem?.labelGlass);
          }
        }

        if (index !== 0) {
          // Set Dimension variable
          (dimensionItem?.formulaHeight as [])?.forEach((item) => {
            if (_.isObject(item)) {
              const formulaItem: FormulaObject = item;
              if (
                !formulaItem?.isConstant &&
                formulaItem.type !== VARIABLE_TYPE.BRACKET_OPEN &&
                formulaItem.type !== VARIABLE_TYPE.BRACKET_CLOSE
              ) {
                heightVariable.push({ code: formulaItem?.variable, type: VARIABLE_TYPE.DIMENSION });
              }
            } else {
              heightVariable.push({ code: item, type: VARIABLE_TYPE.DIMENSION });
            }
          });
          (dimensionItem?.formulaWidth as [])?.forEach((item) => {
            if (_.isObject(item)) {
              const formulaItem: FormulaObject = item;
              if (
                !formulaItem?.isConstant &&
                formulaItem.type !== VARIABLE_TYPE.BRACKET_OPEN &&
                formulaItem.type !== VARIABLE_TYPE.BRACKET_CLOSE
              ) {
                widthVariable.push({ code: formulaItem?.variable, type: VARIABLE_TYPE.DIMENSION });
              }
            } else {
              widthVariable.push({ code: item, type: VARIABLE_TYPE.DIMENSION });
            }
          });

          // set Glass ID item
          if (dimensionItem?.labelGlass?.startsWith('G')) {
            labelGlass.push(dimensionItem?.labelGlass);
          }

          // Set sub formula variable
          const subHeightVariable = exportSubFormula(dimensionItem?.formulaHeight);
          const subWidthVariable = exportSubFormula(dimensionItem?.formulaWidth);
          if (subHeightVariable) {
            heightVariable.push(subHeightVariable);
          }
          if (subWidthVariable) widthVariable.push(subWidthVariable);
        }
      });
      state.glassVariable = _.sortBy(_.uniq(labelGlass));
      state.heightVariable = _.sortBy(_.uniqBy(heightVariable, 'code'), ['code']);
      state.widthVariable = _.sortBy(_.uniqBy(widthVariable, 'code'), ['code']);
    },

    updateComponentTemplateVariable(state, action) {
      state.isUpdateFormulaLoading = false;
      const dimensions = action.payload?.configDetails?.dimensionData as [];
      const heightVariable: { code: string; type: string; formula?: any[] }[] = [
        { code: 'HC', type: VARIABLE_TYPE.DIMENSION },
      ];
      const widthVariable: { code: string; type: string; formula?: any[] }[] = [
        { code: 'WC', type: VARIABLE_TYPE.DIMENSION },
      ];
      const labelGlass: string[] = [];
      dimensions?.forEach((dimensionItem: any, index) => {
        // set Glass ID item
        if (dimensions?.length < 2) {
          if (dimensionItem?.labelGlass?.startsWith('G')) {
            labelGlass.push(dimensionItem?.labelGlass);
          }
        }

        if (index !== 0) {
          // Set Dimension variable
          (dimensionItem?.formulaHeight as [])?.forEach((item) => {
            if (_.isObject(item)) {
              const formulaItem: FormulaObject = item;
              if (
                !formulaItem?.isConstant &&
                formulaItem.type !== VARIABLE_TYPE.BRACKET_OPEN &&
                formulaItem.type !== VARIABLE_TYPE.BRACKET_CLOSE
              ) {
                heightVariable.push({ code: formulaItem?.variable, type: VARIABLE_TYPE.DIMENSION });
              }
            } else {
              heightVariable.push({ code: item, type: VARIABLE_TYPE.DIMENSION });
            }
          });
          (dimensionItem?.formulaWidth as [])?.forEach((item) => {
            if (_.isObject(item)) {
              const formulaItem: FormulaObject = item;
              if (
                !formulaItem?.isConstant &&
                formulaItem.type !== VARIABLE_TYPE.BRACKET_OPEN &&
                formulaItem.type !== VARIABLE_TYPE.BRACKET_CLOSE
              ) {
                widthVariable.push({ code: formulaItem?.variable, type: VARIABLE_TYPE.DIMENSION });
              }
            } else {
              widthVariable.push({ code: item, type: VARIABLE_TYPE.DIMENSION });
            }
          });

          // set Glass ID item
          if (dimensionItem?.labelGlass?.startsWith('G')) {
            labelGlass.push(dimensionItem?.labelGlass);
          }

          // Set sub formula variable
          const subHeightVariable = exportSubFormula(dimensionItem?.formulaHeight);
          const subWidthVariable = exportSubFormula(dimensionItem?.formulaWidth);

          if (subHeightVariable) {
            heightVariable.push(subHeightVariable);
          }
          if (subWidthVariable) widthVariable.push(subWidthVariable);
        }
      });
      state.glassVariable = _.sortBy(_.uniq(labelGlass));
      state.heightVariable = _.sortBy(_.uniqBy(heightVariable, 'code'), ['code']);
      state.widthVariable = _.sortBy(_.uniqBy(widthVariable, 'code'), ['code']);
    },

    updateSelectedSystemCode(state, action) {
      state.selectedSystemCode = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

export const { actions } = slice;

// ----------------------------------------------------------------------

export function getComponentTemplateBySystem(code: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      services
        .getComponentTemplateBySystem(code)
        .then((response: any) => {
          dispatch(slice.actions.updateComponentTemplateByType(response?.data));
        })
        .catch((error: any) => {
          dispatch(slice.actions.hasError(error));
        });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function updateSelectedSystemCode(code: string) {
  return () => {
    dispatch(slice.actions.updateSelectedSystemCode(code));
  };
}

// ----------------------------------------------------------------------

export function updateFormulaByComponentConfiguration(data: any) {
  return () => {
    dispatch(slice.actions.updateComponentConfigurationVariable(data));
  };
}
// ----------------------------------------------------------------------

export function updateFormulaByComponentTemplate(data: any) {
  return () => {
    dispatch(slice.actions.updateComponentTemplateVariable(data));
  };
}

// ----------------------------------------------------------------------
// mapping sub formula
export const exportSubFormula = (formulaArr: string[] | FormulaObject[]) => {
  if (formulaArr?.length > 1) {
    let subFormulaText = '';
    let subFormula: FormulaObject[] = [];

    formulaArr?.forEach((item, index) => {
      if (_.isObject(item)) {
        const formulaItem = item as FormulaObject;
        if (index === formulaArr?.length - 1) {
          subFormulaText =
            subFormulaText + mappingLabelVariable(formulaItem?.variable, formulaItem.type);
        } else {
          subFormulaText =
            subFormulaText +
            mappingLabelVariable(formulaItem?.variable, formulaItem.type) +
            mappingCalc(
              formulaItem?.calculation,
              formulaItem?.type,
              (formulaArr[index + 1] as FormulaObject)?.type
            );
        }
        subFormula.push(formulaItem);
      } else {
        if (index === 0) {
          subFormulaText = item;
        } else {
          subFormulaText = subFormulaText + `-${item}`;
        }
        subFormula.push({ variable: item, calculation: '-', type: VARIABLE_TYPE.DIMENSION });
      }
    });

    return {
      code: subFormulaText,
      type: VARIABLE_TYPE.SUB_FORMULA,
      formula: subFormula,
    };
  }
};

export const exportSubFormulaComponent = (formulaArr: string[] | FormulaObject[]) => {
  if (formulaArr?.length > 0) {
    let subFormulaText = '';
    let subFormula: FormulaObject[] = [];

    formulaArr?.forEach((item, index) => {
      if (_.isObject(item)) {
        const formulaItem = item as FormulaObject;
        if (index === formulaArr?.length - 1) {
          subFormulaText =
            subFormulaText + mappingLabelVariable(formulaItem?.variable, formulaItem.type);
        } else {
          subFormulaText =
            subFormulaText +
            mappingLabelVariable(formulaItem?.variable, formulaItem.type) +
            mappingCalc(
              formulaItem?.calculation,
              formulaItem?.type,
              (formulaArr[index + 1] as FormulaObject)?.type
            );
        }
        subFormula.push(formulaItem);
      } else {
        if (index === 0) {
          subFormulaText = item;
        } else {
          subFormulaText = subFormulaText + `-${item}`;
        }
        subFormula.push({ variable: item, calculation: '-', type: VARIABLE_TYPE.DIMENSION });
      }
    });

    return {
      code: subFormulaText,
      type: VARIABLE_TYPE.SUB_FORMULA,
      formula: subFormula,
    };
  }
};

// ----------------------------------------------------------------------

const mappingLabelVariable = (variable: string, type?: string) => {
  switch (type) {
    case VARIABLE_TYPE.BRACKET_OPEN:
      return '(';
    case VARIABLE_TYPE.BRACKET_CLOSE:
      return ')';
    default:
      return variable;
  }
};
// ----------------------------------------------------------------------

const mappingCalc = (calc: string, currentType?: string, nextType?: string) => {
  if (currentType === VARIABLE_TYPE.BRACKET_OPEN) {
    return '';
  }
  if (nextType === VARIABLE_TYPE.BRACKET_CLOSE) {
    return '';
  }
  return calc;
};
