import { createSlice } from '@reduxjs/toolkit';
// redux
import { dispatch } from '../store';
// services
import * as services from 'services/configuration';
import { SYSTEM_CONFIGURATION_TYPE } from 'utils/constants';

// ----------------------------------------------------------------------

export type ConfigurationType = {
  id: string;
  code: string;
  name: string;
  active: boolean;
};

type ConfigurationStateType = {
  isLoading: boolean;
  error: string | null;
  joinerySystems: ConfigurationType[];
  productTypes: ConfigurationType[];
  labourTypes: ConfigurationType[];
  unitOfMeasures: ConfigurationType[];
  colors: ConfigurationType[];
  configurationList: any;
};

// ----------------------------------------------------------------------

const initialState: ConfigurationStateType = {
  isLoading: false,
  error: null,
  joinerySystems: [],
  productTypes: [],
  labourTypes: [],
  unitOfMeasures: [],
  colors: [],
  configurationList: null,
};

const slice = createSlice({
  name: 'configuration',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    updateConfigurationList(state, action) {
      state.isLoading = false;
      state.configurationList = action.payload;
    },

    updateConfigurationByType(state, action) {
      state.isLoading = false;

      const payloadData = action.payload;
      switch (payloadData.type) {
        case SYSTEM_CONFIGURATION_TYPE.JOINERY_SYSTEM:
          state.configurationList.joinerySystems = payloadData.data;
          break;

        case SYSTEM_CONFIGURATION_TYPE.PRODUCT_TYPE:
          state.configurationList.productTypes = payloadData.data;
          break;

        case SYSTEM_CONFIGURATION_TYPE.UNIT_OF_MEASURE:
          state.configurationList.unitOfMeasures = payloadData.data;
          break;

        case SYSTEM_CONFIGURATION_TYPE.COLOR:
          state.configurationList.colors = payloadData.data;
          break;

        case SYSTEM_CONFIGURATION_TYPE.MATERIAL_TYPE:
          state.configurationList.materialTypes = payloadData.data;
          break;

        case SYSTEM_CONFIGURATION_TYPE.LABOUR:
          state.configurationList.labourTypes = payloadData.data;
          break;

        case SYSTEM_CONFIGURATION_TYPE.LOCATION:
          state.configurationList.locations = payloadData.data;
          break;

        case SYSTEM_CONFIGURATION_TYPE.CUT_ANGLE:
          state.configurationList.cutAngles = payloadData.data;
          break;

        default:
          state.configurationList.joinerySystems = payloadData.data;
          break;
      }
    },

    setConfigurations: (state, action) => {
      const { joinerySystems, productTypes, labourTypes, unitOfMeasures, colors } = action.payload;
      state.isLoading = false;
      state.joinerySystems = joinerySystems;
      state.productTypes = productTypes;
      state.labourTypes = labourTypes;
      state.unitOfMeasures = unitOfMeasures;
      state.colors = colors;
    },

    clearError(state) {
      state.error = null;
    },
  },
});

// Reducer

export default slice.reducer;
export const { actions } = slice;

// ----------------------------------------------------------------------

export function getConfigurations() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      services
        .getConfigurations()
        .then((response: any) => {
          dispatch(actions.setConfigurations(response?.data[0]));
          dispatch(actions.clearError());
        })
        .catch((error: any) => {
          dispatch(slice.actions.hasError(error?.message));
        });
    } catch (error) {
      dispatch(slice.actions.hasError(error?.message));
    }
  };
}

// ----------------------------------------------------------------------

export function getConfigurationList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      services
        .getConfigurationList()
        .then((response: any) => {
          dispatch(slice.actions.updateConfigurationList(response?.data[0]));
        })
        .catch((error: any) => {
          dispatch(slice.actions.hasError(error?.message));
        });
    } catch (error) {
      dispatch(slice.actions.hasError(error?.message));
    }
  };
}
// ----------------------------------------------------------------------

export function getConfigurationByType(type: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      services
        .getConfigurationByType(type)
        .then((response: any) => {
          dispatch(slice.actions.updateConfigurationByType({ type: type, data: response?.data }));
        })
        .catch((error: any) => {
          dispatch(slice.actions.hasError(error?.message));
        });
    } catch (error) {
      dispatch(slice.actions.hasError(error?.message));
    }
  };
}

// ----------------------------------------------------------------------

export function createConfiguration(params: {
  configurationType: string;
  code: string;
  name: string;
}) {
  return async () => {
    dispatch(slice.actions.startLoading());
    return services
      .createConfiguration(params)
      .then((res) => {
        dispatch(getConfigurationByType(params.configurationType));
      })
      .catch((error: any) => {
        dispatch(slice.actions.hasError(error?.message));
        throw error;
      });
  };
}

// ----------------------------------------------------------------------

export function updateConfiguration(
  id: string,
  params: {
    configurationType: string;
    code: string;
    name: string;
  }
) {
  return async () => {
    dispatch(slice.actions.startLoading());
    return services
      .updateConfiguration(id, params)
      .then(() => {
        dispatch(getConfigurationByType(params.configurationType));
      })
      .catch((error: any) => {
        dispatch(slice.actions.hasError(error?.message));
        throw error;
      });
  };
}

// ----------------------------------------------------------------------

export function inactiveConfiguration(id: string, type: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    return services
      .updateConfigurationStatus(id, type)
      .then(() => {
        dispatch(getConfigurationByType(type));
      })
      .catch((error: any) => {
        dispatch(slice.actions.hasError(error?.message));
        throw error;
      });
  };
}
