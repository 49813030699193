import { createSlice } from '@reduxjs/toolkit';
// @types

// redux
import { dispatch } from '../store';
// services
import * as services from 'services/material';
import { MATERIAL_TYPE } from 'utils/constants';

// ----------------------------------------------------------------------

type InitialState = {
  isLoading: boolean;
  error: Error | string | null;
  materialList: any;
};
const initialState: InitialState = {
  isLoading: false,
  error: null,
  materialList: null,
};

const slice = createSlice({
  name: 'material',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    updateMaterialList(state, action) {
      state.isLoading = false;
      state.materialList = action.payload;
    },

    updateMaterialByType(state, action) {
      state.isLoading = false;
      const payloadData = action.payload;
      switch (payloadData.type) {
        case MATERIAL_TYPE.ALUMINIUM:
          state.materialList.aluminium = payloadData.data;
          break;
        case MATERIAL_TYPE.GLASS:
          state.materialList.glass = payloadData.data;
          break;

        case MATERIAL_TYPE.HARDWARE:
          state.materialList.hardware = payloadData.data;
          break;

        case MATERIAL_TYPE.LINER:
          state.materialList.liner = payloadData.data;
          break;

        case MATERIAL_TYPE.SYSTEM_DIMENSION_VARIABLE:
          state.materialList.systemDimensionVariable = payloadData.data;
          break;

        default:
          state.materialList.aluminium = payloadData.data;
      }
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getMaterialList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      services
        .getMaterials()
        .then((response: any) => {
          dispatch(slice.actions.updateMaterialList(response?.data));
        })
        .catch((error: any) => {
          dispatch(slice.actions.hasError(error?.message));
        });
    } catch (error) {
      dispatch(slice.actions.hasError(error?.message));
    }
  };
}
// ----------------------------------------------------------------------

export function updateMaterials(data: any) {
  return () => {
    try {
      dispatch(slice.actions.updateMaterialList(data));
    } catch (error) {}
  };
}

// ----------------------------------------------------------------------

export function getMaterialByType(type: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      services
        .getMaterialByType(type)
        .then((response: any) => {
          dispatch(slice.actions.updateMaterialByType({ type: type, data: response?.data }));
        })
        .catch((error: any) => {
          dispatch(slice.actions.hasError(error?.message));
        });
    } catch (error) {
      dispatch(slice.actions.hasError(error?.message));
    }
  };
}

// ----------------------------------------------------------------------

export function createMaterial(params: {
  code: string;
  name: string;
  type: string;
  systemCode: string;
  uomCode: string;
  stockLength?: number | string;
  salesPrice: number | string;
  cost: number | string;
}) {
  return async () => {
    dispatch(slice.actions.startLoading());

    return services
      .createMaterial(params)
      .then(() => {
        dispatch(getMaterialByType(params.type));
      })
      .catch((error: any) => {
        dispatch(slice.actions.hasError(error?.message));
        throw error;
      });
  };
}
// ----------------------------------------------------------------------

export function updateMaterial(
  id: string,
  params: {
    code: string;
    name: string;
    type: string;
    systemCode: string;
    uomCode: string;
    stockLength?: number | string;
    salesPrice: number | string;
    cost: number | string;
  }
) {
  return async () => {
    dispatch(slice.actions.startLoading());
    return services
      .updateMaterial(id, params)
      .then(() => {
        // dispatch(getMaterialByType(params.type));
      })
      .catch((error: any) => {
        dispatch(slice.actions.hasError(error?.message));
        throw error;
      });
  };
}
// ----------------------------------------------------------------------

export function updateMaterialStatus(id: string, type: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      services
        .updateMaterialStatus(id)
        .then(() => {
          dispatch(getMaterialByType(type));
        })
        .catch((error: any) => {
          dispatch(slice.actions.hasError(error?.message));
        });
    } catch (error) {
      dispatch(slice.actions.hasError(error?.message));
    }
  };
}
// ----------------------------------------------------------------------
