import * as endpoints from 'services/config-template/endpoints';
import * as api from 'utils/axios';
// _types
import { ConfigTemplateType } from '_types/configTemplate';

export const getConfigTemplate = (systemCode?: string) =>
  api.sendGet(endpoints.GET_CONFIG_TEMPLATE + (systemCode ? `?systemCode=${systemCode}` : ''), {
    headers: { 'Content-Type': 'application/json', accept: 'application/json' },
    data: {},
  });

export const getConfigTemplateBySystem = (systemCode: String) =>
  api.sendGet(endpoints.GET_CONFIG_TEMPLATE_BY_SYSTEM + `/?systemCode=${systemCode}`, {
    headers: { 'Content-Type': 'application/json', accept: 'application/json' },
    data: {},
  });

export const getConfigTemplateDetail = (configTemplateId: string) =>
  api.sendGet(`${endpoints.GET_CONFIG_TEMPLATE}/${configTemplateId}`, {
    headers: { 'Content-Type': 'application/json', accept: 'application/json' },
    data: {},
  });

export const createConfigTemplate = (params: ConfigTemplateType) =>
  api.sendPost(endpoints.CREATE_CONFIG_TEMPLATE, params);

export const updateConfigTemplate = (params: ConfigTemplateType) =>
  api.sendPut(`${endpoints.UPDATE_CONFIG_TEMPLATE}/${params.id}`, params, {
    headers: { 'Content-Type': 'application/json' },
  });

export const deleteConfigTemplate = (configTemplateId: string) =>
  api.sendDelete(`${endpoints.DELETE_CONFIG_TEMPLATE}/${configTemplateId}`, {
    headers: { 'Content-Type': 'application/json' },
    data: {
      configTemplateId,
    },
  });
