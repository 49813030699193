import { createSlice } from '@reduxjs/toolkit';
// @types

// redux
import { dispatch } from '../store';
// services
import * as configTemplateServices from 'services/config-template';
import * as services from 'services/product-template';

//lodash
import _ from 'lodash';

// utils
import { IRREGULAR_TYPE, VARIABLE_TYPE } from 'utils/constants';
import { FormulaObject } from '_types/product';

// ----------------------------------------------------------------------

type InitialState = {
  isLoading: boolean;
  error: Error | string | null;
  isLoadingProductTemplateList: boolean;
  errorProductTemplateList: Error | string | null;
  productTemplateByType: any;
  configTemplate: any;
  selectedSystemCode: string;
  selectedProductTypeCode: string;
  heightVariable: { code: string; type: string; formula?: any[] }[];
  widthVariable: { code: string; type: string; formula?: any[] }[];
  irregularVariable?: { code: string; type: string; formula?: any[] }[];
  variable: string[];
  glassVariable: string[];
  isUpdateFormulaLoading: boolean;
  updateFormulaError: Error | string | null;
  edgeLabelList: string[];
};
const initialState: InitialState = {
  isLoading: false,
  error: null,
  configTemplate: [],
  isLoadingProductTemplateList: false,
  errorProductTemplateList: null,
  productTemplateByType: [],
  selectedSystemCode: '',
  selectedProductTypeCode: '',
  heightVariable: [{ code: 'H', type: VARIABLE_TYPE.DIMENSION }],
  widthVariable: [{ code: 'W', type: VARIABLE_TYPE.DIMENSION }],
  irregularVariable: [],
  variable: [],
  glassVariable: [],
  isUpdateFormulaLoading: false,
  updateFormulaError: null,
  edgeLabelList: [],
};

const slice = createSlice({
  name: 'productTemplate',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // START LOADING
    startLoadingProductTemplateByType(state) {
      state.isLoadingProductTemplateList = true;
    },

    // HAS ERROR
    hasErrorProductTemplateByType(state, action) {
      state.isLoadingProductTemplateList = false;
      state.errorProductTemplateList = action.payload;
    },

    updateProductTemplateByType(state, action) {
      state.isLoadingProductTemplateList = false;
      state.productTemplateByType = action.payload;
    },

    updateConfigTemplate(state, action) {
      state.isLoading = false;
      state.configTemplate = action.payload;
    },

    updateSelectedSystemCode(state, action) {
      state.selectedSystemCode = action.payload;
    },

    updateSelectedProductTypeCode(state, action) {
      state.selectedProductTypeCode = action.payload;
    },

    // START LOADING
    startUpdateVariableLoading(state) {
      state.isUpdateFormulaLoading = true;
    },

    // HAS ERROR
    hasUpdateVariableError(state, action) {
      state.isUpdateFormulaLoading = false;
      state.updateFormulaError = action.payload;
    },

    updateVariable(state, action) {
      state.isUpdateFormulaLoading = false;

      const isIrregularWindow = action.payload?.shapeType === 'POLYGON';
      const dimensions = action.payload?.dimensionData as [];
      const heightVariable: { code: string; type: string; formula?: any[] }[] = [
        { code: 'H', type: VARIABLE_TYPE.DIMENSION },
      ];
      const widthVariable: { code: string; type: string; formula?: any[] }[] = [
        { code: 'W', type: VARIABLE_TYPE.DIMENSION },
      ];
      let irregularVariable: { code: string; type: string; formula?: any[] }[] = [];
      let labelGlass: string[] = [];
      dimensions?.forEach((dimension: any, index) => {
        // set Glass ID item
        if (isIrregularWindow) {
          labelGlass = mappingIrregularGlass(dimension?.type);
        } else {
          if (dimensions?.length < 2) {
            if (dimension?.labelGlass?.startsWith('G')) {
              labelGlass.push(dimension?.labelGlass);
            }
          }
        }
        irregularVariable = mappingIrregularVariable(dimension?.type);
        if (index !== 0) {
          // Set Dimension variable
          (dimension?.formulaHeight as [])?.forEach((item) => {
            if (_.isObject(item)) {
              const formulaItem: FormulaObject = item;
              if (
                !formulaItem?.isConstant &&
                formulaItem.type !== VARIABLE_TYPE.BRACKET_OPEN &&
                formulaItem.type !== VARIABLE_TYPE.BRACKET_CLOSE
              ) {
                heightVariable.push({ code: formulaItem?.variable, type: VARIABLE_TYPE.DIMENSION });
              }
            } else {
              heightVariable.push({ code: item, type: VARIABLE_TYPE.DIMENSION });
            }
          });
          (dimension?.formulaWidth as [])?.forEach((item) => {
            if (_.isObject(item)) {
              const formulaItem: FormulaObject = item;
              if (
                !formulaItem?.isConstant &&
                formulaItem.type !== VARIABLE_TYPE.BRACKET_OPEN &&
                formulaItem.type !== VARIABLE_TYPE.BRACKET_CLOSE
              ) {
                widthVariable.push({ code: formulaItem?.variable, type: VARIABLE_TYPE.DIMENSION });
              }
            } else {
              widthVariable.push({ code: item, type: VARIABLE_TYPE.DIMENSION });
            }
          });

          // set Glass ID item
          if (dimension?.labelGlass?.startsWith('G')) {
            labelGlass.push(dimension?.labelGlass);
          }

          // Set sub formula variable
          const subHeightVariable = exportSubFormula(dimension?.formulaHeight);
          const subWidthVariable = exportSubFormula(dimension?.formulaWidth);

          if (subHeightVariable) {
            heightVariable.push(subHeightVariable);
          }
          if (subWidthVariable) widthVariable.push(subWidthVariable);
        }
      });

      state.edgeLabelList = isIrregularWindow
        ? mappingIrregularEdge(action.payload?.polygonModel)
        : [];

      state.glassVariable = _.sortBy(_.uniq(labelGlass));
      state.heightVariable = _.sortBy(_.uniqBy(heightVariable, 'code'), ['code']);
      state.widthVariable = _.sortBy(_.uniqBy(widthVariable, 'code'), ['code']);
      state.irregularVariable = _.sortBy(_.uniqBy(irregularVariable, 'code'), ['code']);
    },

    updateComponentConfigurationVariable(state, action) {
      state.isUpdateFormulaLoading = false;
      const dimensions = action.payload?.configDetails?.dimensionData as [];
      const heightVariable: { code: string; type: string; formula?: any[] }[] = [
        { code: 'HC', type: VARIABLE_TYPE.DIMENSION },
      ];
      const widthVariable: { code: string; type: string; formula?: any[] }[] = [
        { code: 'WC', type: VARIABLE_TYPE.DIMENSION },
      ];
      const labelGlass: string[] = [];
      dimensions?.forEach((dimensionItem: any, index) => {
        // set Glass ID item
        if (dimensions?.length < 2) {
          if (dimensionItem?.labelGlass?.startsWith('G')) {
            labelGlass.push(dimensionItem?.labelGlass);
          }
        }

        if (index !== 0) {
          // Set Dimension variable
          (dimensionItem?.formulaHeight as [])?.forEach((item) => {
            if (_.isObject(item)) {
              const formulaItem: FormulaObject = item;
              if (
                !formulaItem?.isConstant &&
                formulaItem.type !== VARIABLE_TYPE.BRACKET_OPEN &&
                formulaItem.type !== VARIABLE_TYPE.BRACKET_CLOSE
              ) {
                heightVariable.push({ code: formulaItem?.variable, type: VARIABLE_TYPE.DIMENSION });
              }
            } else {
              heightVariable.push({ code: item, type: VARIABLE_TYPE.DIMENSION });
            }
          });
          (dimensionItem?.formulaWidth as [])?.forEach((item) => {
            if (_.isObject(item)) {
              const formulaItem: FormulaObject = item;
              if (
                !formulaItem?.isConstant &&
                formulaItem.type !== VARIABLE_TYPE.BRACKET_OPEN &&
                formulaItem.type !== VARIABLE_TYPE.BRACKET_CLOSE
              ) {
                widthVariable.push({ code: formulaItem?.variable, type: VARIABLE_TYPE.DIMENSION });
              }
            } else {
              widthVariable.push({ code: item, type: VARIABLE_TYPE.DIMENSION });
            }
          });

          // set Glass ID item
          if (dimensionItem?.labelGlass?.startsWith('G')) {
            labelGlass.push(dimensionItem?.labelGlass);
          }

          // Set sub formula variable
          const subHeightVariable = exportSubFormula(dimensionItem?.formulaHeight);
          const subWidthVariable = exportSubFormula(dimensionItem?.formulaWidth);

          if (subHeightVariable) {
            heightVariable.push(subHeightVariable);
          }
          if (subWidthVariable) widthVariable.push(subWidthVariable);
        }
      });
      state.glassVariable = _.sortBy(_.uniq(labelGlass));
      state.heightVariable = _.sortBy(_.uniqBy(heightVariable, 'code'), ['code']);
      state.widthVariable = _.sortBy(_.uniqBy(widthVariable, 'code'), ['code']);
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------
// mapping sub formula
const exportSubFormula = (formulaArr: string[] | FormulaObject[]) => {
  if (formulaArr?.length > 1) {
    let subFormulaText = '';
    let subFormula: FormulaObject[] = [];

    formulaArr?.forEach((item, index) => {
      if (_.isObject(item)) {
        const formulaItem = item as FormulaObject;
        if (index === formulaArr?.length - 1) {
          subFormulaText =
            subFormulaText + mappingLabelVariable(formulaItem?.variable, formulaItem.type);
        } else {
          subFormulaText =
            subFormulaText +
            mappingLabelVariable(formulaItem?.variable, formulaItem.type) +
            mappingCalc(
              formulaItem?.calculation,
              formulaItem?.type,
              (formulaArr[index + 1] as FormulaObject)?.type
            );
        }
        subFormula.push(formulaItem);
      } else {
        if (index === 0) {
          subFormulaText = item;
        } else {
          subFormulaText = subFormulaText + `-${item}`;
        }
        subFormula.push({ variable: item, calculation: '-', type: VARIABLE_TYPE.DIMENSION });
      }
    });

    return {
      code: subFormulaText,
      type: VARIABLE_TYPE.SUB_FORMULA,
      formula: subFormula,
    };
  }
};

export function updateFormulaByConfigTemplate(data: any) {
  return () => {
    dispatch(slice.actions.updateVariable(data));
  };
}

// ----------------------------------------------------------------------

export function createProductTemplate(params: {
  configTemplateId: string;
  typeCode: string;
  systemCode: string;
  configDetails: any;
}) {
  return async () => {
    dispatch(slice.actions.startLoading());

    return services
      .createProductTemplate(params)
      .then((res: any) => {})
      .catch((error: any) => {
        dispatch(slice.actions.hasError(error?.message));
        throw error;
      });
  };
}

// ----------------------------------------------------------------------

export function getProductTemplateBySystem(code: string) {
  return async () => {
    dispatch(slice.actions.startLoadingProductTemplateByType());
    try {
      services
        .getProductTemplateBySystem(code)
        .then((response: any) => {
          dispatch(slice.actions.updateProductTemplateByType(response?.data));
        })
        .catch((error: any) => {
          dispatch(slice.actions.hasErrorProductTemplateByType(error));
        });
    } catch (error) {
      dispatch(slice.actions.hasErrorProductTemplateByType(error));
    }
  };
}

// ----------------------------------------------------------------------
export function getConfigTemplate() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      configTemplateServices
        .getConfigTemplate()
        .then((response: any) => {
          dispatch(slice.actions.updateConfigTemplate(response?.data));
        })
        .catch((error: any) => {
          dispatch(slice.actions.hasError(error?.message));
        });
    } catch (error) {
      dispatch(slice.actions.hasError(error?.message));
    }
  };
}

// ----------------------------------------------------------------------

export function updateSelectedSystemCode(code: string) {
  return () => {
    dispatch(slice.actions.updateSelectedSystemCode(code));
  };
}
// ----------------------------------------------------------------------

export function updateSelectedProductTypeCode(code: string) {
  return () => {
    dispatch(slice.actions.updateSelectedProductTypeCode(code));
  };
}
// ----------------------------------------------------------------------
export function deleteProductTemplate(id: string, system: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    return services
      .deleteProductTemplate(id)
      .then(() => {
        dispatch(getProductTemplateBySystem(system));
      })
      .catch((error: any) => {
        dispatch(slice.actions.hasError(error?.message));
        throw error;
      });
  };
}
// ----------------------------------------------------------------------

const mappingLabelVariable = (variable: string, type?: string) => {
  switch (type) {
    case VARIABLE_TYPE.BRACKET_OPEN:
      return '(';
    case VARIABLE_TYPE.BRACKET_CLOSE:
      return ')';
    default:
      return variable;
  }
};

const mappingCalc = (calc: string, currentType?: string, nextType?: string) => {
  if (currentType === VARIABLE_TYPE.BRACKET_OPEN) {
    return '';
  }
  if (nextType === VARIABLE_TYPE.BRACKET_CLOSE) {
    return '';
  }
  return calc;
};

const mappingIrregularVariable = (type: string) => {
  switch (type) {
    case IRREGULAR_TYPE.R_TRIANGLE_LEFT:
      return [{ code: 'HYP(H, W)', type: VARIABLE_TYPE.SYSTEM_METHOD }];
    case IRREGULAR_TYPE.R_TRIANGLE_RIGHT:
      return [{ code: 'HYP(W, H)', type: VARIABLE_TYPE.SYSTEM_METHOD }];
    case IRREGULAR_TYPE.TRAPEZOID_LEFT:
    case IRREGULAR_TYPE.TRAPEZOID_RIGHT:
      return [
        { code: 'H1', type: VARIABLE_TYPE.DIMENSION },
        { code: 'HYP(H-H1, W)', type: VARIABLE_TYPE.SYSTEM_METHOD },
      ];
    case IRREGULAR_TYPE.E_TRIANGLE:
      return [{ code: 'HYP(W/2, H)', type: VARIABLE_TYPE.SYSTEM_METHOD }];
    case IRREGULAR_TYPE.E_TRIANGLE_HAFT_SPLITTED:
      return [{ code: 'HYP(H, W/2)', type: VARIABLE_TYPE.SYSTEM_METHOD }];
    case IRREGULAR_TYPE.E_TRIANGLE_QUARTER_SPLITTED:
      return [{ code: 'HYP(H, W/2)', type: VARIABLE_TYPE.SYSTEM_METHOD }];
    default:
      return [];
  }
};

const mappingIrregularGlass = (type: string) => {
  switch (type) {
    case IRREGULAR_TYPE.E_TRIANGLE_HAFT_SPLITTED:
      return ['G1-F', 'G2-F'];
    case IRREGULAR_TYPE.E_TRIANGLE_QUARTER_SPLITTED:
      return ['G1-F', 'G2-F', 'G3-F', 'G4-F'];
    default:
      return ['G1-F'];
  }
};

const mappingIrregularEdge = (type: string) => {
  switch (type) {
    case IRREGULAR_TYPE.R_TRIANGLE_LEFT:
    case IRREGULAR_TYPE.R_TRIANGLE_RIGHT:
    case IRREGULAR_TYPE.E_TRIANGLE:
      return ['AC', 'CB', 'BA'];

    case IRREGULAR_TYPE.TRAPEZOID_LEFT:
    case IRREGULAR_TYPE.TRAPEZOID_RIGHT:
      return ['AD', 'DC', 'CB', 'BA'];

    case IRREGULAR_TYPE.E_TRIANGLE_HAFT_SPLITTED:
      return ['AC', 'CB', 'BA', 'DB'];

    case IRREGULAR_TYPE.E_TRIANGLE_QUARTER_SPLITTED:
      return ['AE', 'EC', 'CA', 'HB', 'GC', 'DF'];
    default:
      return [];
  }
};
