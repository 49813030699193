import * as endpoints from 'services/material/endpoints';
import * as api from 'utils/axios';

export const getMaterials = () =>
  api.sendGet(endpoints.MATERIAL, {
    headers: { 'Content-Type': 'application/json', accept: 'application/json' },
    data: {},
  });

export const getMaterialByType = (type: string) =>
  api.sendGet(endpoints.MATERIAL_BY_TYPE + `${type}`, {
    headers: { 'Content-Type': 'application/json', accept: 'application/json' },
    data: {},
  });

export const getMultipliers = () =>
  api.sendGet(endpoints.MULTIPLIER, {
    headers: { 'Content-Type': 'application/json', accept: 'application/json' },
    data: {},
  });

export const getMultiplierByType = (type: string) =>
  api.sendGet(endpoints.MULTIPLIER_BY_TYPE + `${type}`, {
    headers: { 'Content-Type': 'application/json', accept: 'application/json' },
    data: {},
  });

export const createMaterial = (params: any) => api.sendPost(endpoints.MATERIAL, params);

export const updateMaterial = (id: string, params: any) =>
  api.sendPatch(endpoints.MATERIAL + `/${id}`, params, {
    headers: { 'Content-Type': 'application/json' },
  });

export const updateMaterialStatus = (id: string) =>
  api.sendPatch(
    endpoints.UPDATE_STATUS.replace('{materialId}', id).replace('{status}', 'false'),
    {},
    {
      headers: { 'Content-Type': 'application/json' },
    }
  );

export const deleteMaterial = (materialId: string) =>
  api.sendDelete(endpoints.DELETE_MATERIAL.replace('{materialId}', materialId), {
    headers: { 'Content-Type': 'application/json' },
    data: {
      materialId,
    },
  });

export const updateMultiplier = (params: { type: string; percentage: number }) =>
  api.sendPut(endpoints.MULTIPLIER, params, {
    headers: { 'Content-Type': 'application/json' },
  });

export const getGetPrefabProduct = (systemCode: string, category: string) =>
  api.sendGet(
    `${endpoints.PREFAB_PRODUCT_BY_CATEGORY}?systemCode=${systemCode}&category=${category}`,
    {
      headers: { 'Content-Type': 'application/json', accept: 'application/json' },
      data: {},
    }
  );

export const updateMaterialOdooProduct = (request: {
  materialId: string;
  params: { odooProductId: string; odooProductName: string };
}) =>
  api.sendPatch(
    endpoints.UPDATE_MATERIAL_ODOO_PRODUCT.replace('{materialId}', request.materialId),
    request.params,
    {
      headers: { 'Content-Type': 'application/json' },
    }
  );
