// ----------------------------------------------------------------------

// function path(root: string, sublink: string) {
//   return `${root}${sublink}`;
// }

export const PATH_PAGE = {
  //product
  configProduct: '/config-product',
  //admin
  materialList: '/admin/material-list',
  systemConfiguration: '/admin/system-configuration',
  productTemplate: {
    root: '/admin/product-template',
    new: '/admin/product-template/new',
    edit: '/admin/product-template/:productTemplateId/edit',
    similar: '/admin/product-template/:productTemplateId/similar',
  },
  componentTemplate: {
    root: '/admin/component-template',
    new: '/admin/component-template/new',
    edit: '/admin/component-template/:componentTemplateId/edit',
  },
  labour: '/admin/labour',
  configTemplate: {
    root: '/admin/config-template',
    new: '/admin/config-template/new',
    edit: '/admin/config-template/:configTemplateId/edit',
  },
  componentConfiguration: {
    root: '/admin/component-configuration',
    new: '/admin/component-configuration/new',
    edit: '/admin/component-configuration/:componentConfigurationId/edit',
  },
  //project
  project: {
    root: '/project',
    list: '/project/list',
  },
  //tools
  tools: {
    root: '/tools',
    transferOrders: '/tools/transfer-orders',
  },
  //auth
  login: '/login',
  signup: '/sign-up',
  forgotPassword: '/forgot-password',
  //error
  page403: '/403',
  page404: '/404',
  page500: '/500',
};
