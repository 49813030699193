import { Navigate } from 'react-router-dom';
// hooks
import { useHasPermission } from 'hooks/useCheckPermission';
// routes
import { PATH_PAGE } from 'routes/paths';
// ----------------------------------------------------------------------

type RoleBasedGuardProp = {
  hasContent?: boolean;
  roles?: string[];
  children: React.ReactNode;
  disallowedRoles?: string[];
};

export default function RoleBasedGuard({
  hasContent,
  roles,
  children,
  disallowedRoles,
}: RoleBasedGuardProp) {

  const isAllowed = useHasPermission(roles || []);
  const isDisallowed = useHasPermission(disallowedRoles || []);

  if (
    (typeof roles !== 'undefined' && !isAllowed) ||
    (typeof disallowedRoles !== 'undefined' && isDisallowed)
  ) {
    return hasContent ? <Navigate to={PATH_PAGE.page403} /> : null;
  }


  return <>{children}</>;
}
