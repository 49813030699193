import { createSlice } from '@reduxjs/toolkit';
// @types
import { LabourStateType, LabourDetailType, LabourParamsType } from '_types/labour';
// redux
import { dispatch } from '../store';
// services
import * as services from 'services/labour';

// ----------------------------------------------------------------------

const initialState: LabourStateType = {
  isLoading: false,
  error: null,
  labours: [],
  labour: null,
  isLoadingLabourComponent: false,
  selectedSystemCode: '',
};

// ----------------------------------------------------------------------

export const slice = createSlice({
  name: 'labour',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    setLabours(state, action) {
      state.isLoading = false;
      state.labours = action.payload;
    },

    setLabour(state, action) {
      state.isLoading = false;
      state.labour = action.payload;
    },

    updateLabour(state, action) {
      state.isLoading = false;
      state.labour = action.payload;
      state.labours = state.labours.map((labour) =>
        labour.id === action.payload.id ? action.payload : labour
      );
    },

    updateLoadingLabourComponent(state, action) {
      state.isLoadingLabourComponent = action.payload;
    },

    updateSelectedSystemCode(state, action) {
      state.selectedSystemCode = action.payload;
    },
    clearError(state) {
      state.error = null;
    },
  },
});

export default slice.reducer;
export const { actions } = slice;

// ----------------------------------------------------------------------

export function addLabour(params: Partial<LabourParamsType>) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: {
        data: LabourDetailType;
      } & any = await services.addLabour(params);
      return response?.data;
    } catch (error) {
      dispatch(
        slice.actions.hasError(
          error?.message || error?.data?.message || error?.data?.error || 'An error has occurred'
        )
      );
    }
  };
}

// ----------------------------------------------------------------------

export function getLabours() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      services
        .getLabourList()
        .then((response: any) => {
          dispatch(slice.actions.setLabours(response?.data));
        })
        .catch((error: any) => {
          dispatch(slice.actions.hasError(error?.message));
        });
    } catch (error) {
      dispatch(slice.actions.hasError(error?.message));
    }
  };
}

// ----------------------------------------------------------------------

export function getLabourDetail(id: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      services
        .getLabourById(id)
        .then((response: any) => {
          dispatch(slice.actions.setLabour(response?.data));
        })
        .catch((error: any) => {
          dispatch(slice.actions.hasError(error?.message));
        });
    } catch (error) {
      dispatch(slice.actions.hasError(error?.message));
    }
  };
}

// ----------------------------------------------------------------------

export function updateLabour(id: string, params: LabourParamsType) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: {
        data: LabourDetailType;
      } & any = await services.updateLabour(id, params);
      dispatch(slice.actions.setLabour(response?.data));
      return response?.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error?.message));
    }
  };
}

// ----------------------------------------------------------------------

export function updateLoadingLabourComponent(payload: boolean) {
  return () => {
    try {
      dispatch(slice.actions.updateLoadingLabourComponent(payload));
    } catch (error) {}
  };
}

// ----------------------------------------------------------------------

export function updateLabourSelectedSystemCode(code: string) {
  return () => {
    dispatch(slice.actions.updateSelectedSystemCode(code));
  };
}
