import * as endpoints from 'services/configuration/endpoints';
import * as api from 'utils/axios';

export const getConfigurationList = () =>
  api.sendGet(endpoints.CONFIGURATION, {
    headers: { 'Content-Type': 'application/json', accept: 'application/json' },
    data: {},
  });

export const createConfiguration = (params: {
  configurationType: string;
  code: string;
  name: string;
}) => api.sendPost(endpoints.CONFIGURATION, params);

export const updateConfiguration = (
  id: string,
  params: {
    configurationType: string;
    code: string;
    name: string;
  }
) =>
  api.sendPatch(endpoints.UPDATE_CONFIGURATION.replace('{systemConfigurationTypeId}', id), params, {
    headers: { 'Content-Type': 'application/json' },
  });

export const updateConfigurationStatus = (code: string, type: string) =>
  api.sendDelete(endpoints.CONFIGURATION, {
    headers: { 'Content-Type': 'application/json' },
    data: {
      configurationType: type,
      code: code,
    },
  });

export const getConfigurationByType = (type: string) =>
  api.sendGet(endpoints.GET_CONFIGURATION_BY_TYPE.replace('{systemConfigurationType}', type), {
    headers: { 'Content-Type': 'application/json', accept: 'application/json' },
    data: {},
  });

export const getConfigurations = () =>
  api.sendGet(endpoints.GET_CONFIGURATIONS, {
    headers: { 'Content-Type': 'application/json', accept: 'application/json' },
    data: {},
  });
