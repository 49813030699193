import * as endpoints from 'services/standardised-rates/endpoints';
import * as api from 'utils/axios';

export const getStandardisedRateList = () =>
  api.sendGet(endpoints.STANDARDISED_RATE, {
    headers: { 'Content-Type': 'application/json', accept: 'application/json' },
    data: {},
  });

export const updateStandardisedRate = (code: string, params: any) =>
  api.sendPut(endpoints.STANDARDISED_RATE + `/${code}`, params, {
    headers: { 'Content-Type': 'application/json' },
  });
