import { createSlice } from '@reduxjs/toolkit';
// @types

// redux
import { dispatch } from '../store';
// services
import * as services from 'services/standardised-rates';

// ----------------------------------------------------------------------

type InitialState = {
  isLoading: boolean;
  error: Error | string | null;
  standardisedRateList: any;
};
const initialState: InitialState = {
  isLoading: false,
  error: null,
  standardisedRateList: null,
};

const slice = createSlice({
  name: 'standardisedRates',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    updateStandardRateList(state, action) {
      state.isLoading = false;
      state.standardisedRateList = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getStandardisedRateList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      services
        .getStandardisedRateList()
        .then((response: any) => {
          dispatch(slice.actions.updateStandardRateList(response?.data));
        })
        .catch((error: any) => {
          dispatch(slice.actions.hasError(error?.message));
        });
    } catch (error) {
      dispatch(slice.actions.hasError(error?.message));
    }
  };
}

// ----------------------------------------------------------------------

export function updateStandardisedRate(
  code: string,
  params: {
    name: string;
    stockLength?: number | string;
    newPrice: number | string;
  }
) {
  return async () => {
    dispatch(slice.actions.startLoading());
    return services
      .updateStandardisedRate(code, params)
      .then(() => {
        dispatch(getStandardisedRateList());
      })
      .catch((error: any) => {
        dispatch(slice.actions.hasError(error?.message));
        throw error;
      });
  };
}
// ----------------------------------------------------------------------
