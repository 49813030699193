import * as endpoints from 'services/project/endpoints';
import * as api from 'utils/axios';
import { GetProjectsParamsType } from '_types/project';

export const addProject = (params: any) => api.sendPost(endpoints.ADD_PROJECT, params);

export const updateProject = (id: string, params: any) =>
  api.sendPatch(endpoints.UPDATE_PROJECT.replace('{id}', id), params, {
    headers: { 'Content-Type': 'application/json' },
  });

export const getProjects = ({
  searchKey,
  pageIndex,
  pageSize,
  sortBy,
  sortDirection,
}: GetProjectsParamsType) =>
  api.sendGet(
    `${endpoints.SEARCH_PROJECTS}?searchKey=${searchKey}&pageIndex=${pageIndex}&pageSize=${pageSize}&sortBy=${sortBy}&sortDirection=${sortDirection}`,
    {
      headers: { 'Content-Type': 'application/json', accept: 'application/json' },
      data: {},
    }
  );

export const getProjectById = (id: string) =>
  api.sendGet(endpoints.GET_PROJECT.replace('{id}', id), {
    headers: { 'Content-Type': 'application/json', accept: 'application/json' },
    data: {},
  });

export const updateProjectStatus = (id: string, isActive: boolean) =>
  api.sendPatch(
    `${endpoints.UPDATE_PROJECT_STATUS.replace('{id}', id)}/${isActive.toString()}`,
    null,
    {
      headers: { 'Content-Type': 'application/json' },
    }
  );

export const cloneProject = (params: { projectId: string }) =>
  api.sendPost(endpoints.CLONE_PROJECT.replace('{projectId}', params?.projectId), {
    params,
  });

export const startProduction = (projectId: string) =>
  api.sendPost(endpoints.START_PRODUCTION.replace('{projectId}', projectId), {
    projectId,
  });

export const deleteProject = (projectId: string) =>
  api.sendDelete(`${endpoints.DELETE_PROJECT}/${projectId}`, {
    headers: { 'Content-Type': 'application/json' },
    data: {
      projectId,
    },
  });
export const downloadAluminiumCuttingSheet = (projectId: string) =>
  api.sendGet(endpoints.DOWNLOAD_ALU_CUTTING_SHEET.replace('{projectId}', projectId), {
    headers: { 'Content-Type': 'application/json', accept: 'application/json' },
    data: {
      projectId,
    },
  });

export const getRValue = () =>
  api.sendGet(endpoints.GET_R_VALUE, {
    headers: { 'Content-Type': 'application/json', accept: 'application/json' },
    data: {},
  });

export const duplicateProject = (params: { projectId: string }) =>
  api.sendPost(endpoints.DUPLICATE_PROJECT.replace('{projectId}', params?.projectId), {
    params,
  });

export const getExtraFlashingByProjectId = (projectId: string) =>
  api.sendGet(endpoints.GET_EXTRA_FLASHING_BY_PROJECT_ID.replace('{projectId}', projectId), {
    headers: { 'Content-Type': 'application/json', accept: 'application/json' },
    data: {},
  });

export const updateExtraFlashingList = (params: { projectId: string; dataList: any }) =>
  api.sendPost(
    endpoints.UPDATE_EXTRA_FLASHING_BY_PROJECT_ID.replace('{projectId}', params?.projectId),
    params.dataList
  );
