import { createSlice } from '@reduxjs/toolkit';
// redux
import { dispatch } from '../store';
// _types
import { CalcDimensionVariableType, DimensionVariableType } from '_types/dimensionVariable';
import { VARIABLE_TYPE } from 'utils/constants';

// ----------------------------------------------------------------------

type InitialState = {
  isLoading: boolean;
  error: Error | string | null;
  dimensionVariableList: DimensionVariableType[] | [];
  calcDimensionVariableList: CalcDimensionVariableType[] | [];
};
const initialState: InitialState = {
  isLoading: false,
  error: null,
  dimensionVariableList: [],
  calcDimensionVariableList: [],
};

const slice = createSlice({
  name: 'dimensionVariables',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    updateSystemDimensionVariableList(state, action) {
      const baseVariable = action.payload?.map((item: DimensionVariableType) => ({
        ...item,
        type: VARIABLE_TYPE.BASE,
      }));

      state.dimensionVariableList = baseVariable;
    },
    updateSystemCalcDimensionVariableList(state, action) {
      const calcVariable = action.payload?.map((item: DimensionVariableType) => ({
        ...item,
        type: VARIABLE_TYPE.CALC,
      }));
      state.calcDimensionVariableList = calcVariable;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function updateSystemDimensionVariableList(dimensionData: DimensionVariableType[]) {
  return () => {
    try {
      dispatch(slice.actions.updateSystemDimensionVariableList(dimensionData));
    } catch (error) {}
  };
}

// ----------------------------------------------------------------------
export function updateSystemCalcDimensionVariableList(data: CalcDimensionVariableType[]) {
  return () => {
    try {
      dispatch(slice.actions.updateSystemCalcDimensionVariableList(data));
    } catch (error) {}
  };
}

// ----------------------------------------------------------------------
