import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
// slices
import kanbanReducer from './slices/kanban';
import configTemplateReducer from './slices/configTemplate';
import componentTemplateReducer from './slices/componentTemplate';
import componentConfigReducer from './slices/componentConfig';
import productReducer from './slices/product';
import materialReducer from './slices/material';
import projectReducer from './slices/project';
import labourReducer from './slices/labour';
import configurationReducer from './slices/configuration';
import productTemplateReducer from './slices/productTemplate';
import dimensionVariableReducer from './slices/dimensionVariables';
import standardisedRatesReducer from './slices/standardisedRates';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const rootReducer = combineReducers({
  kanban: kanbanReducer,
  configTemplate: configTemplateReducer,
  componentConfig: componentConfigReducer,
  componentTemplate: componentTemplateReducer,
  project: projectReducer,
  product: productReducer,
  productTemplate: productTemplateReducer,
  material: materialReducer,
  labour: labourReducer,
  configuration: configurationReducer,
  dimensionVariable: dimensionVariableReducer,
  standardisedRates: standardisedRatesReducer,
});

export { rootPersistConfig, rootReducer };
